import { NextPage } from 'next'
import styles from '../styles/components/Spinner.module.scss'

type Props = {
  size: number
  white?: boolean
}

const Spinner: NextPage<Props> = ({ size, white }) => (
  <svg className={styles.spinner} viewBox="0 0 50 50" width={size} height={size}>
    <circle className={`${styles.path}${white ? ` ${styles.white}` : ''}`} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
  </svg>
)

export default Spinner