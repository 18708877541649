import { AffiliationCodesGetByCode } from "./types"

const affiliationCodesGetByCode: AffiliationCodesGetByCode = async ({ token, code }) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_TRUESCREEN_API_ENDPOINT}/affiliation-codes/codes/${code}`, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })

  if (!response.ok) {
    const info = await response.json()
    const status = response.status

    throw { info, status }
  }

  const data = await response.json()
  console.log('data', data)
  return data
}

export default affiliationCodesGetByCode
