import React from 'react'

import Spinner from '../components/Spinner'
import ProductButton from './ProductButton'

import styles from '../styles/components/ProductsList.module.scss'

import useProductsGet from '../hooks/useProductsGet'

const ProductsList = () => {
  const { data: dataProducts, error: errorProducts } = useProductsGet()

  if (errorProducts) {
    return (<div>Error in retrieving products</div>)
  }

  return (
    !dataProducts ?
      <Spinner size={25} />
      :
      (
        <div className={styles.container}>
          {dataProducts.map((product) => (
            <ProductButton product={product} key={product.id} />
          ))}
        </div>
      )
  )
}

export default ProductsList