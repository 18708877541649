import React, { createContext, useMemo, useState } from "react";

import type AffiliationCode from "../types/affiliationCode";

type ValidAffiliationCodeContextType = [AffiliationCode | null, React.Dispatch<React.SetStateAction<AffiliationCode | null>>]

type Props = {
  children: React.ReactNode
}

const ValidAffiliationCodeContext = createContext<ValidAffiliationCodeContextType>([null, () => null]);


const ValidAffiliationCodeContextProvider = ({ children }: Props) => {
  const [validAffiliationCode, setValidAffiliationCode] = useState<AffiliationCode | null>(null);

  const value = useMemo<ValidAffiliationCodeContextType>(() => [validAffiliationCode, setValidAffiliationCode], [validAffiliationCode])

  return (
    <ValidAffiliationCodeContext.Provider value={value}>
      {children}
    </ValidAffiliationCodeContext.Provider>
  )
}

export {
  ValidAffiliationCodeContextProvider,
  ValidAffiliationCodeContext
}