import { useEffect, useState } from "react";

import type { UseFirebaseToken } from "./types";

const useFirebaseToken: UseFirebaseToken = (AuthUser) => {
  const [firebaseToken, setFirebaseToken] = useState<string | null>(null);

  useEffect(() => {
    const asyncWrapper = async () => {
      const token = await AuthUser.getIdToken()

      setFirebaseToken(token)
    }

    asyncWrapper()
  });

  return firebaseToken;
}

export default useFirebaseToken