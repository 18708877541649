import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";

import useProductsGet from "../hooks/useProductsGet";
import { fetchGetJSON } from "../utils/apiHelpers";
import affiliationCodesGetByCode from "../utils/truescreen-api/affiliationCodes/affiliationCodesGetByCode";
import { ValidAffiliationCodeContext } from "./ValidAffiliationCodeContext";

import styles from "../styles/pages/Index.module.scss";
import getContent from "../utils/getContent";
import { useRouter } from "next/dist/client/router";

type Props = {
  token: string | null;
};

const AffiliationCode = ({ token }: Props) => {
  const router = useRouter();
  const locale = router.locale || "it";
  const { mutate: mutateProductsGet, isValidating: isValidatingProducts } =
    useProductsGet();

  const [codeValidated, setCodeValidated] = useState(false);
  const [validAffiliationCode, setValidAffiliationCode] = useContext(
    ValidAffiliationCodeContext
  );
  const [affiliationCode, setAffiliationCode] = useState("");

  const {
    data: dataAffiliationCode,
    error: errorAffiliationCode,
    isValidating: isValidatingAffiliationCode,
    mutate,
  } = useSWR(
    token && codeValidated ? "affiliationCodesGetByCode" : null,
    () =>
      affiliationCodesGetByCode({
        token: token as string,
        code: affiliationCode,
      }),
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  // if affiliationCodesGetByCode returned a valid response call products list with the affiliation flag a true
  useEffect(() => {
    if (!errorAffiliationCode) {
      if (dataAffiliationCode) {
        mutateProductsGet(
          () => fetchGetJSON("/api/products?affiliation=true"),
          { revalidate: false }
        );
        setValidAffiliationCode(dataAffiliationCode);
      }
    } else {
      mutateProductsGet(() => fetchGetJSON("/api/products"), {
        revalidate: false,
      });
      setValidAffiliationCode(null);
    }
  }, [
    dataAffiliationCode,
    errorAffiliationCode,
    mutateProductsGet,
    setValidAffiliationCode,
  ]);

  const onInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setAffiliationCode(event.currentTarget.value);
    setCodeValidated(false);
  };

  const onclickButton = () => {
    mutate(
      () =>
        affiliationCodesGetByCode({
          token: token as string,
          code: affiliationCode,
        }),
      { revalidate: false }
    );
    // this is needed to avoid to have an api call on first page rendering, because uesSWR triggers call immediately if there is no check
    setCodeValidated(true);
  };

  return (
    <>
      <div className={styles.textContainer}>
        <div className={styles["body-text"]}>
          {getContent(
            "AFFILIATION_CODE_INTRO",
            process.env.NEXT_PUBLIC_PURCHASE_ENV,
            locale
          )}
        </div>

        <div className={styles["form-container"]}>
          <label htmlFor="affiliationCode">
            <div className={styles["label-text"]}>
              {getContent(
                "AFFILIATION_CODE_LABEL",
                process.env.NEXT_PUBLIC_PURCHASE_ENV,
                locale
              )}
            </div>
            <input
              className={styles.input}
              type="text"
              onChange={onInputChange}
              id="affiliationCode"
            />
          </label>
          <button
            onClick={onclickButton}
            className={styles.submit}
            type="button"
            disabled={isValidatingProducts || isValidatingAffiliationCode}
          >
            {getContent(
              "AFFILIATION_CODE_SUBMIT",
              process.env.NEXT_PUBLIC_PURCHASE_ENV,
              locale
            )}
          </button>
        </div>

        <div
          className={`${styles["input-note"]}${
            codeValidated === false || isValidatingAffiliationCode
              ? ` ${styles["empty"]}`
              : ""
          }`}
        >
          {codeValidated === false || isValidatingAffiliationCode ? (
            <>&nbsp;</>
          ) : validAffiliationCode === null ? (
            <div className={styles["error"]}>
              {getContent(
                "AFFILIATION_CODE_INVALID",
                process.env.NEXT_PUBLIC_PURCHASE_ENV,
                locale
              )}
            </div>
          ) : (
            <>
              {getContent(
                "AFFILIATION_CODE_VALID",
                process.env.NEXT_PUBLIC_PURCHASE_ENV,
                locale
              )}{" "}
              <strong>{validAffiliationCode?.company?.name}</strong>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AffiliationCode;
