import React from "react";
import Image from "next/image";

import styles from "../styles/components/DownloadApps.module.scss";

import IosBadge from "../public/apple-store-badge.png";
import AndroidBadge from "../public/play-store-badge.png";
import { useRouter } from "next/dist/client/router";

const DownloadApps = () => {
  const router = useRouter();
  const locale = router.locale || "it";
  return (
    <div className={styles.container}>
      <a
        href={`https://apps.apple.com/${locale}/app/${process.env.NEXT_PUBLIC_APPLE_APP_ID}?itsct=apps_box_link&itscg=30200`}
        target="_blank"
        rel="noreferrer"
      >
        <Image src={IosBadge} alt="Download App" width={150} height={58} />
      </a>
      <a
        href={`https://play.google.com/store/apps/details?id=${process.env.NEXT_PUBLIC_GOOGLE_APP_ID}`}
        target="_blank"
        rel="noreferrer"
      >
        <Image src={AndroidBadge} alt="Download App" width={150} height={58} />
      </a>
    </div>
  );
};

export default DownloadApps;
