import { useCallback } from "react";
import { useAuthUser } from "next-firebase-auth";

import styles from "../styles/components/Footer.module.scss";
import { useRouter } from "next/dist/client/router";
import getContent from "../utils/getContent";

const Footer = () => {
  const router = useRouter();
  const locale = router.locale || "it";
  const AuthUser = useAuthUser();

  const signOutOnclick = useCallback(() => {
    AuthUser.signOut();
  }, [AuthUser]);

  return (
    <div className={styles.container}>
      <div className={styles.footer}>
        <a href={`https://www.truescreen.app/mobile_app/${locale}/terms.html`}>
          {getContent(
            "TERMS_SERVICE",
            process.env.NEXT_PUBLIC_PURCHASE_ENV,
            locale
          )}
        </a>{" "}
        |{" "}
        <a href="https://www.iubenda.com/privacy-policy/37859944">
          Privacy Policy
        </a>{" "}
        |{" "}
        <a
          href={
            locale === "it"
              ? "https://truescreen.app/faq/"
              : "https://truescreen.app/en/faq/"
          }
        >
          FAQ
        </a>
        {AuthUser.id !== null ? (
          <>
            {" "}
            |{" "}
            <span className={styles["fake-link"]} onClick={signOutOnclick}>
              {getContent("EXIT", process.env.NEXT_PUBLIC_PURCHASE_ENV, locale)}
            </span>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Footer;
