import { withAuthUser } from 'next-firebase-auth'

import Head from 'next/head'
import Link from 'next/link'

import styles from '../styles/components/Layout.module.scss'
import DownloadApps from './DownloadApps'
import Footer from './Footer'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <Head>
        <title>TrueScreen - Acquista crediti</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="apple-mobile-web-app-title" content="TrueScreen" />
        <meta name="application-name" content="TrueScreen" />
        <meta name="msapplication-TileColor" content="#ffc40d" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <div className={styles.container}>
        <Link href="/" passHref>
          <a>
            <div className={styles.logoContainer}>
              {/* eslint-disable-next-line @next/next/no-img-element*/}
              <img src={`/logo-${process.env.NEXT_PUBLIC_PURCHASE_ENV}.svg`} alt="TueScreen" />
            </div>
          </a>
        </Link>
        <main className={styles.main}>
          {children}
        </main>
        <DownloadApps />
        <Footer />
      </div>
    </>
  )
}

export default withAuthUser<Props>()(Layout)