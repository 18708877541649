const contents: {
  [key: string]: { [key: string]: { [key: string]: string } };
} = {
  truescreen: {
    TITLE: {
      it: "Acquista crediti",
      en: "Purchase credits",
    },
    SUBTITLE: {
      it: "Acquista crediti in anticipo e risparmia sulle tue prossime certificazioni",
      en: "Buy credits in advance and save on your next certifications",
    },
    MAIN_TEXT: {
      it: "Acquistando in anticipo i crediti puoi spendere meno per le certificazioni di screenshot, registrazioni video e screen display code® video",
      en: "By purchasing credits in advance, you can spend less on certifications of screenshots, video recordings, and screen display code® video",
    },
    AFFILIATION_CODE_INTRO: {
      it: "Possiedi un codice di affiliazione? utilizzalo per ottenere sconti riservati",
      en: "Do you have an affiliation code? use it to get reserved discounts",
    },
    AFFILIATION_CODE_LABEL: {
      it: "Codice di Affiliazione",
      en: "Affiliation code",
    },
    AFFILIATION_CODE_SUBMIT: { it: "Applica codice", en: "Apply code" },
    AFFILIATION_CODE_VALID: {
      it: "Acquista a prezzo scontato grazie all'affiliazione a",
      en: "Buy at a discounted price thanks to affiliation with",
    },
    AFFILIATION_CODE_INVALID: { it: "Codice non valido", en: "Code not valid" },
    TERMS_SERVICE: { it: "Termini di servizio", en: "Terms of service" },
    EXIT: { it: "Esci", en: "Log out" },
    CREDITS: { it: "crediti", en: "credits" },
    WELCOME: { it: "Benvenuto", en: "Welcome" },
    YOUR_CREDITS: { it: "I Tuoi crediti", en: "Your credits" },
    TRANSACTION_EXISTS: {
      it: "Questa transazione è già stata registrata",
      en: "This transaction has already been registered",
    },
    CREDITS_NOW: { it: "Crediti attuali", en: "Your credits" },
    PURCHASE_AGAIN: { it: "Acquista di nuovo", en: "Purchase again" },
    LOGIN_TITLE: {
      it: "Acquista crediti in anticipo e risparmia sulle tue prossime certificazioni",
      en: "Buy credits in advance and save on your next certifications",
    },
    LOGIN_TEXT: {
      it: "Acquistando in anticipo i crediti puoi spendere meno per le certificazioni di screenshot, registrazioni video e screen display code® video",
      en: "By purchasing credits in advance, you can spend less on certifications of screenshots, video recordings, and screen display code® video",
    },
  },
};

export default contents;
