import useSWR from "swr"

import { fetchGetJSON } from "../utils/apiHelpers"

import type Product from "../types/product"


const useProductsGet = () => {
  const { data, error, mutate, isValidating } = useSWR<Array<Product>, { info: string, status: number }>(
    'productsGet',
    () => fetchGetJSON('/api/products'),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  return { data, error, mutate, isValidating }
}

export default useProductsGet