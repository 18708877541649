import React from 'react'
import Image from 'next/image'

import Spinner from './Spinner'
import coin from '../public/coin.svg'

import styles from '../styles/components/CurrentCredits.module.scss'

import type Credits from '../types/credits'


type Props = {
  credits: Credits | undefined,
  label: string
}

const CurrentCredits = ({ credits, label }: Props) => (
  <div className={styles.container}>
    <div className={styles.title}>{label}</div>
    <div className={styles.credits}>
      {credits
        ?
        <>
          <Image src={coin} alt="coin" unoptimized />
          <div className={styles.number}>{credits.available}</div>
        </>
        : <Spinner size={25} />}
    </div>
  </div>
)

export default CurrentCredits