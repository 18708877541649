import type { FormatAmountFromStripe } from "./types"

export const formatAmountFromStripe: FormatAmountFromStripe = ({ amount, currency = 'EUR', locale = 'it-IT' }) => {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })

  return numberFormat.format(amount / 100)
}