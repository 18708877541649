import React from "react";
import useSWR from "swr";
import * as Sentry from "@sentry/browser";

import Spinner from "./Spinner";
import userMe from "../utils/truescreen-api/user/userMe";

import styles from "../styles/components/UserData.module.scss";
import { useRouter } from "next/dist/client/router";
import getContent from "../utils/getContent";

type Props = {
  token: string | null;
};

const UserData = ({ token }: Props) => {
  const router = useRouter();
  const locale = router.locale || "it";
  const { data: dataUser, error: errorUser } = useSWR(
    token ? "userMe" : null,
    () => userMe({ token: token as string })
  );

  Sentry.setUser({ id: dataUser?.id, email: dataUser?.email });

  if (errorUser) {
    return <div>Error in retrieving user</div>;
  }
  return (
    <div className={`${styles["body-text"]} ${styles["name-container"]}`}>
      {dataUser ? (
        <>
          {getContent("WELCOME", process.env.NEXT_PUBLIC_PURCHASE_ENV, locale)}
          {dataUser.first_name && dataUser.last_name ? (
            <>
              {" "}
              {dataUser.first_name} {dataUser.last_name}
            </>
          ) : (
            <> {dataUser.email}</>
          )}
        </>
      ) : (
        <Spinner size={19.25} />
      )}
    </div>
  );
};

export default UserData;
