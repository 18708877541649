import React, { useContext, useMemo, useState } from "react";
import { useAuthUser } from "next-firebase-auth";
import useSWR from "swr";
import Image from "next/image";

import coin from "../public/coin.svg";

import Spinner from "./Spinner";
import { ValidAffiliationCodeContext } from "./ValidAffiliationCodeContext";
import userMe from "../utils/truescreen-api/user/userMe";
import useFirebaseToken from "../hooks/useGetToken";
import getStripeJs from "../utils/getStripeJs";
import { fetchGetJSON, fetchPostJSON } from "../utils/apiHelpers";
import { formatAmountFromStripe } from "../utils/stripeHelpers";

import styles from "../styles/components/ProductButton.module.scss";

import type Product from "../types/product";
import { useRouter } from "next/dist/client/router";
import getContent from "../utils/getContent";

type Props = {
  product: Product;
};

const ProductButton = ({ product }: Props) => {
  const router = useRouter();
  const locale = router.locale || "it";
  const [validAffiliationCode] = useContext(ValidAffiliationCodeContext);
  const [loading, setLoading] = useState(false);

  const AuthUser = useAuthUser();

  const token = useFirebaseToken(AuthUser);

  const { data: dataUser, error: errorUser } = useSWR(
    token ? "userMe" : null,
    () => userMe({ token: token as string })
  );

  const { data: dataPrice, error: errorPrice } = useSWR(
    `/api/prices?productId=${product.id}`,
    fetchGetJSON,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const priceNumber = useMemo(() => {
    if (!dataPrice) {
      return null;
    }
    const unitAmount = dataPrice?.unit_amount as number;
    return formatAmountFromStripe({ amount: unitAmount });
  }, [dataPrice]);

  if (errorPrice) {
    return <div>Error in retrieving price</div>;
  }

  if (errorUser) {
    return <div>Error in retrieving user</div>;
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Create a Checkout Session.
    const params = {
      stripePriceId: dataPrice?.id,
    } as {
      email: string;
      stripePriceId: string;
      stripeCustomer?: string;
      affiliationCode: string;
      locale: string;
    };

    if (AuthUser.email) {
      params.email = AuthUser.email;
    }

    if (
      dataUser?.stripe_customer_id !== null &&
      dataUser?.stripe_customer_id != ""
    ) {
      params.stripeCustomer = dataUser?.stripe_customer_id;
    }

    if (validAffiliationCode !== null) {
      params.affiliationCode = validAffiliationCode.code;
    }
    params.locale = locale;

    const response = await fetchPostJSON("/api/checkout_sessions", params);

    if (response.statusCode === 500) {
      console.error(response.message);
      return;
    }

    // Redirect to Checkout.
    const stripe = await getStripeJs();

    if (stripe === null) {
      return;
    }

    const { error } = await stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: response.id,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    console.warn(error.message);
    setLoading(false);
  };

  return (
    <button
      className={styles.container}
      onClick={handleClick}
      disabled={loading || !dataPrice}
    >
      <div className={styles["product-name-container"]}>
        <Image src={coin} alt="coin" unoptimized />
        <div className={styles["product-name"]}>
          {parseInt(product.metadata.sort_credits, 10)}{" "}
          {getContent("CREDITS", process.env.NEXT_PUBLIC_PURCHASE_ENV, locale)}
        </div>
      </div>
      {priceNumber ? <div>{priceNumber}</div> : <Spinner size={19} white />}
    </button>
  );
};

export default ProductButton;
