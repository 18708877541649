import { UserMe } from "./types"

const userMe: UserMe = async ({ token }) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_TRUESCREEN_API_ENDPOINT}/user/me`, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })

  const data = await response.json()

  return data
}

export default userMe
