import React from 'react'
import useSWR from 'swr'

import creditsGet from '../utils/truescreen-api/credits/creditsGet'
import CurrentCredits from './CurrentCredits'


type Props = {
  token: string | null,
  label: string
}

const InitialCredits = ({ token, label }: Props) => {
  const { data: dataCredits, error: errorCredits } = useSWR(
    token ? 'creditsGet' : null,
    () => creditsGet({ token: token as string }),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  if (errorCredits) {
    return (<div>Error in retrieving credits</div>)
  }

  return (
    <CurrentCredits credits={dataCredits} label={label} />
  )
}

export default InitialCredits