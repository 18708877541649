import React from "react";
import {
  useAuthUser,
  withAuthUser,
  AuthAction,
  withAuthUserTokenSSR,
} from "next-firebase-auth";
import { NextPage } from "next";

import ProductsList from "../components/ProductsList";
import UserData from "../components/UserData";
import InitialCredits from "../components/InitialCredits";
import useFirebaseToken from "../hooks/useGetToken";
import Product from "../types/product";
import Layout from "../components/Layout";
import AffiliationCode from "../components/AffiliationCode";
import { ValidAffiliationCodeContextProvider } from "../components/ValidAffiliationCodeContext";
import getContent from "../utils/getContent";

import styles from "../styles/pages/Index.module.scss";
import { useRouter } from "next/dist/client/router";

type Props = {
  products: Array<Product>;
};

const Index: NextPage<Props> = () => {
  const AuthUser = useAuthUser();
  const router = useRouter();
  const locale = router.locale || "it";
  const token = useFirebaseToken(AuthUser);

  return (
    <Layout>
      <UserData token={token} />

      <div className={styles.title}>
        {getContent("TITLE", process.env.NEXT_PUBLIC_PURCHASE_ENV, locale)}
      </div>
      <InitialCredits
        token={token}
        label={getContent(
          "YOUR_CREDITS",
          process.env.NEXT_PUBLIC_PURCHASE_ENV,
          locale
        )}
      />

      <div className={styles.textContainer}>
        <div className={styles["sub-title"]}>
          {getContent("SUBTITLE", process.env.NEXT_PUBLIC_PURCHASE_ENV, locale)}
        </div>
        <div className={styles["body-text"]}>
          {getContent(
            "MAIN_TEXT",
            process.env.NEXT_PUBLIC_PURCHASE_ENV,
            locale
          )}
        </div>
      </div>
      <ValidAffiliationCodeContextProvider>
        <AffiliationCode token={token} />
        <ProductsList />
      </ValidAffiliationCodeContextProvider>
    </Layout>
  );
};

export const getServerSideProps = withAuthUserTokenSSR()();

export default withAuthUser<Props>({
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
})(Index);
