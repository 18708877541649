import contents from "../contents/contents";

import type { GetContent } from "./types";

const getContent: GetContent = (
  label,
  env,
  locale,
  defaultEnv = "truescreen"
) => {
  if (contents[env] !== undefined) {
    if (contents[env][label][locale] != undefined) {
      return contents[env][label][locale];
    }
  }

  if (contents[defaultEnv][label][locale] === undefined) {
    return "Missing text";
  }

  return contents[defaultEnv][label][locale];
};

export default getContent;
